import React from 'react';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  CARD_SIZE_SMALL,
  CARD_SIZE_MEDIUM,
  DISCUSSION,
  QUESTION,
} from '@wix/communities-forum-client-commons';
import { Loader } from '@wix/communities-forum-client-commons/components';

import Masonry from '../../../common/components/masonry';
import { getCardSize } from '../../selectors/app-settings-selectors';
import { connect } from '../../../common/components/runtime-context';
import NoPostsFiltered from '../no-posts-filtered';
import LoadMore from '../load-more';
import { getPostPageSize } from '../../constants/pagination';
import PostListItemVirtualized from '../post-list-item-virtualized';
import styles from './post-list-masonry-responsive.scss';
import withAuth from '../../hoc/with-auth';
import { PostBreakpoints, useForumWidthContext } from '../responsive-listener';
import { PostListControls } from './post-list-controls.component';
import { getForumContainerBreakpointValue } from '../../selectors/layout-selectors';

const TYPES = {
  [CARD_SIZE_SMALL]: 'small',
  [CARD_SIZE_MEDIUM]: 'medium',
};

const getMasonryBreakpointsByCardSize = (cardSize) => {
  if (cardSize === CARD_SIZE_SMALL) {
    return {
      default: 3,
      [PostBreakpoints.md]: 2,
      [PostBreakpoints.sm]: 1,
    };
  } else if (cardSize === CARD_SIZE_MEDIUM) {
    return {
      default: 2,
      [PostBreakpoints.sm]: 1,
    };
  }
};

const renderLoader = () => {
  return (
    <div className={styles.loader}>
      <Loader />
    </div>
  );
};

const PostListMasonryResponsive = ({
  category,
  posts,
  postTypes,
  onLikeClick,
  showCreatePostAction,
  showCategoryLink,
  cardSize,
  hasActiveFilter,
  showCategoryFilter,
  loadMore,
  showLoaderInLoadMore,
  entityCount,
  isLoading,
  customCtaLabel,
  showMemberPosts,
  emptyStateFragment,
  isAuthenticated,
  postsPerPage,
}) => {
  const forumWidth = useForumWidthContext();
  const className = classNames(styles.masonry);
  const isOnSecondOrMorePage = Math.ceil(posts.length / postsPerPage) >= 2;

  const renderControls = () => {
    const breakpointValue = getForumContainerBreakpointValue(forumWidth);
    return (
      <PostListControls
        dataHook="post-list-masonry-controls"
        isTop={true}
        showCategoryFilter={showCategoryFilter}
        breakpointValue={breakpointValue}
        isAuthenticated={isAuthenticated}
        showCreatePostAction={showCreatePostAction}
        postTypes={postTypes}
        customCtaLabel={customCtaLabel}
        category={category}
      />
    );
  };
  const showEmptyState = (!isLoading && !posts.length && hasActiveFilter) || emptyStateFragment;
  const emptyState = emptyStateFragment || <NoPostsFiltered noMemberPosts={showMemberPosts} />;

  return (
    <div className={styles.container}>
      {renderControls()}
      <LoadMore
        loadMore={loadMore}
        isLoading={showLoaderInLoadMore}
        showButton={showLoaderInLoadMore || isOnSecondOrMorePage}
        remainingEntities={entityCount - posts.length}
      >
        {showEmptyState ? (
          emptyState
        ) : (
          <Masonry
            columnClassName={styles.masonryColumn}
            containerWidth={forumWidth}
            breakpointCols={getMasonryBreakpointsByCardSize(cardSize)}
            className={className}
            data-hook="post-list-masonry"
          >
            {posts.map((post) =>
              post ? (
                <PostListItemVirtualized
                  key={post._id}
                  post={post}
                  category={category}
                  onLikeClick={onLikeClick}
                  showCategoryLink={showCategoryLink}
                  type={TYPES[cardSize]}
                />
              ) : null,
            )}
          </Masonry>
        )}
        {isLoading && renderLoader()}
      </LoadMore>
    </div>
  );
};

PostListMasonryResponsive.propTypes = {
  onLikeClick: PropTypes.func.isRequired,
  category: PropTypes.object,
  posts: PropTypes.array,
  query: PropTypes.string,
  showCreatePostAction: PropTypes.bool,
  showCategoryLink: PropTypes.bool,
  cardSize: PropTypes.number,
  hasActiveFilter: PropTypes.bool,
  uniquePostTypesInAllCategories: PropTypes.array,
  showCategoryFilter: PropTypes.bool,
  loadMore: PropTypes.func.isRequired,
  showLoaderInLoadMore: PropTypes.bool,
  entityCount: PropTypes.number,
  isLoading: PropTypes.bool,
  showMemberPosts: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  customCtaLabel: PropTypes.string,
  postTypes: PropTypes.arrayOf(PropTypes.oneOf([QUESTION, DISCUSSION])),
  postsPerPage: PropTypes.number,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  cardSize: getCardSize(state, host.style),
  postsPerPage: getPostPageSize(state, host.style),
});

export default flowRight(connect(mapRuntimeToProps), withAuth)(PostListMasonryResponsive);
